import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Color from "../../../assets/colors";
import { getJobDiscovery } from "../../../helpers/posthog";
import { sendTrackingEvent } from "../../../helpers/tracking_management";
import useTriggerSnackbar from "../../../hooks/useTriggerSnackbar";
import { applyJob } from "../../../redux/actions/job_action";
import {
  updateCountryPreference,
  updateCreateResumePopupStatus,
  updateInReviewResumePoupupStatus,
  updateJobCountryPreferenceDialog,
  updateRejectedResumePopupStatus,
} from "../../../redux/actions/user_action";
import * as jobTypes from "../../../redux/types/job_type";
import {
  BoxContainer,
  ButtonStyled,
  CancelApplicationIcon,
  CloseButton,
  CloseIcon,
  DialogContainer,
  DownArrowIcon,
  GlobalChip,
  GlobalIcon,
  MYCountryFlag,
  SGCountryFlag,
  SelectContainer,
  SelectDropdown,
  SelectHeader,
  SelectOption,
  TextStyled,
} from "./styles";

function JobCountryPreferenceDialog(props) {
  const { job, onApplyJobClicked, useLocalApply, origin } = props;

  const router = useRouter();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width:640px)");

  const [triggerSnackbarFunc] = useTriggerSnackbar();

  const userData = useSelector((state) => state.user.user);
  const open = useSelector(
    (state) => state.user.showJobCountryPreferenceDialog
  );
  const country = useSelector((state) => state.user.JobCountryPreference);
  const relocationPreferences = useSelector(
    (state) => state?.user?.user?.relocationPreferences
  );
  const rightToWork = useSelector((state) => state?.user?.user?.rightToWork);

  const viewHistoryState = useSelector((state) => state.jobs.viewHistoryState);
  const jobListTab = useSelector((state) => state.jobs.jobListTab);
  const fypTab = Number(useSelector((state) => state.jobs.fypTab));

  const [confirmExit, setConfirmExit] = useState(false);
  const [panel, setPanel] = useState(null);
  const [selectedRelocation, setSelectedRelocation] = useState(
    getRelocationValue(country)
  );
  const [selectedWork, setSelectedWork] = useState(getWorkValue(country));

  function getJobOrigin() {
    if (origin === "individual-job-page") {
      return router.query.origin ?? "individual_job_page";
    }
    if (origin === "job-list") {
      return getJobDiscovery(jobListTab, viewHistoryState, fypTab);
    }
  }

  const jobDiscoveryOrigin = getJobOrigin();

  const countryList = {
    my: { name: "Malaysia", flag: <MYCountryFlag /> },
    sg: { name: "Singapore", flag: <SGCountryFlag /> },
  };

  const relocationOptions = [
    {
      label:
        "I'm willing to relocate (and physically be in office if necessary)",
      value: 1,
    },
    {
      label: "I'm available only remotely (not willing/able to relocate)",
      value: 2,
    },
    {
      label: "I'm currently not interested in jobs from this country",
      value: 3,
    },
  ];

  const workOptions = [
    {
      label: "I require help or sponsorship to obtain a work permit/visa",
      value: 1,
    },
    {
      label: "I have a work permit/visa and am allowed to work in this country",
      value: 2,
    },
    { label: "I am a citizen of this country", value: 3 },
  ];

  const allowApply =
    ((getRelocationValue(country) !== null || selectedRelocation !== null) &&
      (getWorkValue(country) !== null || selectedWork !== null)) ||
    getRelocationValue(country) === 3 ||
    selectedRelocation === 3;

  function getRelocationCountry(country) {
    switch (country) {
      case "sg":
        return relocationPreferences["SG"];
      case "my":
        return relocationPreferences["MY"];
      default:
        return;
    }
  }

  function getRelocationValue(country) {
    if (relocationPreferences === undefined) {
      return;
    }
    const remoteValue = getRelocationCountry(country)?.remote ?? null;
    const relocateValue = getRelocationCountry(country)?.relocate ?? null;

    if (relocateValue === true && remoteValue === false) {
      return 1;
    } else if (relocateValue === false && remoteValue === true) {
      return 2;
    } else if (relocateValue === false && remoteValue === false) {
      return 3;
    } else {
      return null;
    }
  }

  function getWorkCountry(country) {
    if (rightToWork === undefined) {
      return;
    }
    switch (country) {
      case "sg":
        return rightToWork["SG"];
      case "my":
        return rightToWork["MY"];
      default:
        return;
    }
  }

  function getWorkValue(country) {
    const localCitizenValue = getWorkCountry(country)?.localCitizen ?? null;
    const openToSponsorValue = getWorkCountry(country)?.openToSponsor ?? null;
    const visaOrEmploymentPassValue =
      getWorkCountry(country)?.visaOrEmploymentPass ?? null;

    if (openToSponsorValue === true) {
      return 1;
    } else if (visaOrEmploymentPassValue === true) {
      return 2;
    } else if (localCitizenValue === true) {
      return 3;
    } else {
      return null;
    }
  }

  function handleClose() {
    setConfirmExit(true);
  }

  function handleConfirmExit() {
    setSelectedRelocation(getRelocationValue(country));
    setSelectedWork(getWorkValue(country));
    dispatch(updateJobCountryPreferenceDialog(false));
    setConfirmExit(false);
  }

  function handleSubmit(country, selectedRelocation, selectedWork) {
    let remote;
    let relocate;
    let openToSponsor;
    let visaOrEmploymentPass;
    let localCitizen;

    switch (selectedRelocation) {
      case 1:
        remote = false;
        relocate = true;
        break;
      case 2:
        remote = true;
        relocate = false;
        break;
      case 3:
        remote = false;
        relocate = false;
    }

    switch (selectedWork) {
      case 1:
        openToSponsor = true;
        visaOrEmploymentPass = false;
        localCitizen = false;
        break;
      case 2:
        openToSponsor = false;
        visaOrEmploymentPass = true;
        localCitizen = false;
        break;
      case 3:
        openToSponsor = false;
        visaOrEmploymentPass = false;
        localCitizen = true;
        break;
    }

    const params = {
      country: country,
      remote: remote,
      relocate: relocate,
      openToSponsor: openToSponsor,
      visaOrEmploymentPass: visaOrEmploymentPass,
      localCitizen: localCitizen,
    };

    dispatch(updateCountryPreference(params)).then(() => {
      if (useLocalApply === true) {
        applyJobWithCurrentState(job, userData);
      } else {
        onApplyJobClicked(job, userData);
      }
    });
    dispatch(updateJobCountryPreferenceDialog(false));
  }

  async function applyJobWithCurrentState(job, apiResUser) {
    // CONDITION - NO RESUME / RESUME NOT APPROVED
    // Do not send in application if user has no resume/rejected
    if (
      !apiResUser?.resume ||
      apiResUser?.state == "rejected" ||
      apiResUser?.state == "no_resume"
    ) {
      if (!apiResUser?.resume || apiResUser?.state == "no_resume") {
        dispatch(updateCreateResumePopupStatus(true));
      } else if (apiResUser?.state == "rejected") {
        dispatch(updateRejectedResumePopupStatus(true));
      }

      sendTrackingEvent({
        event: "CE_job-application",
        "job-title": job?.title ?? "",
        resume: false,
        logged_in: true,
        "job-bucket-id": null,
        scraped: job?.scraped ? true : false,
      });

      return;
    }

    // CONDITION - SCRAPED JOB
    if (job?.category === "scraped" || job?.category === "aggregated") {
      posthogJobApply(job, "individual_job_page", jobOrigin);
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: true,
        "job-bucket-id": null, // no bucket id due to not calling applyJob API
        "job-title": job?.title,
      });
      return window.open(job?.externalJobUrl, "_blank");
    }

    const response = dispatch(
      applyJob({
        jobId: job?.id,
        origin: origin,
        jobDiscovery: jobDiscoveryOrigin,
      })
    );

    if (response.type === jobTypes.APPLY_JOB_FAILED) {
      triggerSnackbarFunc({
        snackbarMessage: response.errMsg ?? "Error",
        severity: "error",
      });
    } else if (response.type === jobTypes.APPLY_JOB_SUCCEED) {
      sendTrackingEvent({
        event: "CE_job-application",
        resume: true,
        logged_in: true,
        scraped: false,
        "job-bucket-id": response?.payload?.jobBucketId,
        "job-title": response?.payload?.appliedJob?.title,
      });

      if (apiResUser?.state == "in_review") {
        dispatch(updateInReviewResumePoupupStatus(true));
      } else {
        dispatch(updateJobAppliedDialog(true));
        triggerSnackbarFunc({
          snackbarMessage: "Application successful!",
          severity: "success",
        });
      }
    }
  }

  useEffect(() => {
    setSelectedRelocation(getRelocationValue(country));
    setSelectedWork(getWorkValue(country));
  }, [open]);

  return (
    country && (
      <>
        <DialogContainer open={open}>
          <BoxContainer
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ width: "100%", height: "100%" }}
          >
            <BoxContainer
              flexDirection={"column"}
              gap={"0.25rem"}
              padding={isMobile ? "1rem 1rem 0.5rem" : "1rem 1.5rem 0.5rem"}
              borderBottom={"1px solid " + Color.grey}
            >
              <BoxContainer
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <GlobalChip icon={<GlobalIcon />} label="Global hire" />
                <CloseButton onClick={handleClose}>
                  <CloseIcon />
                </CloseButton>
              </BoxContainer>
              <BoxContainer flexDirection={"column"}>
                <TextStyled
                  fontSize={isMobile ? "1rem" : "1.125rem"}
                  fontWeight={"700"}
                  lineHeight={"1.5rem"}
                >
                  Confirm & update your availability
                </TextStyled>
                <TextStyled
                  color={"rgba(0, 0, 0, 0.6)"}
                  fontSize={"0.875rem"}
                  lineHeight={isMobile ? "1.25rem" : "1.5rem"}
                  letterSpacing={"0.15px"}
                >
                  Confirm your right to work & relocation availability to
                  complete your application.
                </TextStyled>
              </BoxContainer>
            </BoxContainer>

            <BoxContainer
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"space-between"}
              padding={isMobile ? "1rem" : "1rem 1.5rem"}
              height={"25.375rem"}
            >
              <BoxContainer
                flexDirection={"column"}
                border={"0.5px solid" + Color.grey}
                borderRadius={"0.5rem"}
                overflow={"clip"}
                width={"100%"}
              >
                <BoxContainer
                  bgcolor={Color.backgroundGrey}
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent={"space-between"}
                  alignItems={isMobile ? "flex-start" : "center"}
                  padding={isMobile ? "0.5rem" : "0.5rem 0.625rem"}
                  width={"100%"}
                >
                  <BoxContainer alignItems={"center"} gap={"0.625rem"}>
                    {countryList[country].flag}
                    <TextStyled
                      fontSize={"1rem"}
                      fontWeight={"700"}
                      lineHeight={"1.5rem"}
                      letterSpacing={"0.1px"}
                    >
                      {countryList[country].name}
                    </TextStyled>
                  </BoxContainer>
                  <TextStyled
                    color={"rgba(0, 0, 0, 0.38)"}
                    fontSize={"0.75rem"}
                    fontStyle={"italic"}
                    lineHeight={"1.25rem"}
                    letterSpacing={"0.096px"}
                  >
                    This information will be shown to{" "}
                    {countryList[country].name} employers.
                  </TextStyled>
                </BoxContainer>
                <OptionContainer
                  title={"Availability & Relocation"}
                  cPanel={1}
                  panel={panel}
                  setPanel={setPanel}
                  selectedDropdown={selectedRelocation}
                  selectedValue={getRelocationValue(country)}
                  setSelectedDropdown={setSelectedRelocation}
                  dropdownOptions={relocationOptions}
                />
                <OptionContainer
                  title={"Right to Work"}
                  cPanel={2}
                  panel={panel}
                  setPanel={setPanel}
                  selectedValue={getWorkValue(country)}
                  selectedDropdown={selectedWork}
                  setSelectedDropdown={setSelectedWork}
                  dropdownOptions={workOptions}
                  disabled={selectedRelocation === 3}
                />
              </BoxContainer>

              <TextStyled
                color={"rgba(0, 0, 0, 0.6)"}
                fontSize={isMobile ? "0.75rem" : "0.875rem"}
                lineHeight={"1.25rem"}
                letterSpacing={"0.112px"}
              >
                We request for an update on your current status every 3 months
                as this information will be showed to potential employers
              </TextStyled>
            </BoxContainer>

            <BoxContainer
              flexDirection={"column"}
              alignItems={"center"}
              gap={"0.625rem"}
              padding={"1rem 1.875rem"}
              width={"100%"}
              borderTop={"1px solid " + Color.grey}
            >
              <ButtonStyled
                onClick={() =>
                  handleSubmit(country, selectedRelocation, selectedWork)
                }
                disabled={!allowApply}
              >
                Complete Application
              </ButtonStyled>
            </BoxContainer>
          </BoxContainer>
        </DialogContainer>
        <CloseDialog
          confirmExit={confirmExit}
          setConfirmExit={setConfirmExit}
          handleConfirmExit={handleConfirmExit}
        />
      </>
    )
  );
}

function OptionContainer(props) {
  const {
    title,
    selectedDropdown,
    setSelectedDropdown,
    cPanel,
    panel,
    setPanel,
    selectedValue,
    dropdownOptions,
    disabled,
  } = props;

  const isMobile = useMediaQuery("(max-width:640px)");

  function handleShow(currentPanel) {
    panel === currentPanel ? setPanel(0) : setPanel(currentPanel);
  }

  function handleChange(value) {
    setSelectedDropdown(value);
    setPanel(0);
  }

  return (
    <BoxContainer
      flexDirection={isMobile ? "column" : "row"}
      gap={"0.375rem"}
      justifyContent={"space-between"}
      alignItems={isMobile ? "flex-start" : "center"}
      borderTop={"0.5px solid" + Color.grey}
      padding={"0.75rem 0.5rem"}
    >
      <TextStyled
        fontSize={"0.875rem"}
        lineHeight={"1.5rem"}
        letterSpacing={"0.15px"}
      >
        {title}
      </TextStyled>
      <BoxContainer>
        <SelectContainer>
          <SelectHeader
            selected={panel === cPanel}
            onClick={() => handleShow(cPanel)}
            width={"22.5rem"}
            disabled={disabled}
          >
            {disabled ? (
              <TextStyled
                color={"rgba(0, 0, 0, 0.38)"}
                fontSize={"0.875rem"}
                lineHeight={"1.5rem"}
                letterSpacing={"0.15px"}
              >
                Not Relevant
              </TextStyled>
            ) : selectedDropdown ? (
              dropdownOptions.map((option, index) =>
                selectedDropdown === option.value ? (
                  <BoxContainer
                    alignItems={"center"}
                    gap={"0.5rem"}
                    overflow={"clip"}
                  >
                    <TextStyled
                      color={"rgba(0, 0, 0, 0.87)"}
                      fontSize={"0.875rem"}
                      lineHeight={"1.25rem"}
                      letterSpacing={"0.112px"}
                      textOverflow={"ellipsis"}
                      overflow={"clip"}
                      whiteSpace={"nowrap"}
                    >
                      {option.label}
                    </TextStyled>
                  </BoxContainer>
                ) : null
              )
            ) : selectedValue ? (
              dropdownOptions.map((option, index) =>
                selectedValue === option.value ? (
                  <BoxContainer
                    alignItems={"center"}
                    gap={"0.5rem"}
                    overflow={"clip"}
                  >
                    <TextStyled
                      color={"rgba(0, 0, 0, 0.87)"}
                      fontSize={"0.875rem"}
                      lineHeight={"1.25rem"}
                      letterSpacing={"0.112px"}
                      textOverflow={"ellipsis"}
                      overflow={"clip"}
                      whiteSpace={"nowrap"}
                    >
                      {option.label}
                    </TextStyled>
                  </BoxContainer>
                ) : null
              )
            ) : (
              <TextStyled
                color={"rgba(0, 0, 0, 0.38)"}
                fontSize={"0.875rem"}
                lineHeight={"1.5rem"}
                letterSpacing={"0.15px"}
              >
                Please Select
              </TextStyled>
            )}

            <DownArrowIcon selected={panel === cPanel} />
          </SelectHeader>
          <SelectDropdown in={panel === cPanel} timeout="auto">
            {dropdownOptions.map((option, index) => (
              <SelectOption
                key={index}
                onClick={() => handleChange(option.value)}
              >
                {option.label}
              </SelectOption>
            ))}
          </SelectDropdown>
        </SelectContainer>
      </BoxContainer>
    </BoxContainer>
  );
}

function CloseDialog(props) {
  const { confirmExit, setConfirmExit, handleConfirmExit } = props;

  const isMobile = useMediaQuery("(max-width:640px)");

  function handleCancel() {
    setConfirmExit(false);
  }

  return (
    <DialogContainer
      open={confirmExit}
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "23.75rem",
          maxWidth: "28.125rem",
          "@media (max-width: 640px)": {
            maxHeight: "18.75rem",
            maxWidth: "20.5rem",
          },
        },
      }}
    >
      <BoxContainer
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ width: "100%", height: "100%" }}
      >
        <BoxContainer
          alignItems={"center"}
          justifyContent={"space-between"}
          padding={"1rem 1rem 0.5rem"}
          borderBottom={"1px solid " + Color.grey}
          width={"100%"}
        >
          <TextStyled
            fontSize={isMobile ? "1rem" : "1.125rem"}
            fontWeight={"700"}
            lineHeight={"1.5rem"}
          >
            Are you sure you want to exit?
          </TextStyled>
          <CloseButton onClick={handleCancel}>
            <CloseIcon />
          </CloseButton>
        </BoxContainer>

        <BoxContainer
          flexDirection={"column"}
          alignItems={"center"}
          padding={isMobile ? "1rem" : "1.5rem 1rem 1rem"}
          gap={isMobile ? "0.5rem" : "1.5rem"}
          height={isMobile ? "100%" : "15.625rem"}
        >
          <CancelApplicationIcon />
          <TextStyled
            color={"rgba(0, 0, 0, 0.87)"}
            fontSize={isMobile ? "0.875rem" : "1rem"}
            lineHeight={"1.5rem"}
            letterSpacing={"0.15px"}
            textAlign={isMobile ? "center" : "left"}
          >
            Your application <b>will not</b> be sent if your Right to Work and
            Availability and Relocation are not submitted.
          </TextStyled>
        </BoxContainer>

        <BoxContainer
          alignItems={"center"}
          gap={"0.5rem"}
          padding={isMobile ? "1rem" : "1rem 1.5rem"}
          width={"100%"}
          borderTop={"1px solid " + Color.grey}
        >
          <ButtonStyled onClick={handleConfirmExit} variant={"outline"}>
            Cancel Application
          </ButtonStyled>
          <ButtonStyled onClick={handleCancel}>Return</ButtonStyled>
        </BoxContainer>
      </BoxContainer>
    </DialogContainer>
  );
}

export default JobCountryPreferenceDialog;
